@use '/styles/variables' as *;
@use '/styles/utils' as u;

.icon {
  color: $g-primary-outline;
  &.glow {
    @extend %filter-shadow-primary;

    @each $key, $value in $colors {
      &-#{'' + $key} {
        @extend %filter-shadow-#{'' + $key};
      }
    }
  }
}
