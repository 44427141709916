@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    color-scheme: dark;
  }
}

@layer components {
  .widget {
    @apply rounded-xl bg-g-elevated border border-g-dividers;
  }

  .btn {
    @apply rounded-xl border border-transparent uppercase tracking-wide px-4 py-2 text-base transition-all;

    &:active {
      @apply scale-95;
    }

    &:disabled {
      @apply pointer-events-none border-g-dividers bg-g-elevated text-g-gray shadow-none;
    }

    & svg {
      @apply inline-block transition-all text-current;
      vertical-align: -0.125em;
      height: 1em;
      width: 1em;
    }
  }

  .input {
    @apply px-4 py-2 rounded-xl border-g-dividers bg-transparent border w-full transition-all
      hover:glow-box-primary hover:glow-box-opacity-40 hover:border-white focus:glow-box-primary focus:glow-box-opacity-40 focus:border-white placeholder:text-g-light-gray;

    &--error {
      @apply border border-white glow-box-g-red glow-box-opacity-40;
    }
  }

  .placeholder {
    @apply rounded-xl bg-gradient-to-r bg-neutral-700 from-neutral-900
      via-neutral-800 to-neutral-900 border border-neutral-700;

    animation: animation 1s linear infinite;
    background-repeat: repeat-x;
    background-size: 400% 100%;

    @keyframes animation {
      0% {
        background-position: 100% 100%;
      }
      100% {
        background-position: -33% 100%;
      }
    }
  }
}

.iti {
  // phone number input container
  width: 100%;
}
.iti__country-list {
  max-width: 90vw;
  background-color: black !important;
  color: white;
  border-radius: 10px;
}

// ===

@import 'variables';
@import 'media';

@font-face {
  font-family: 'Havelock Titling';
  src: url('../public/fonts/HavelockTitling-Medium.woff') format('woff');
  src: url('../public/fonts/HavelockTitling-Medium.woff2') format('woff2');
  font-display: swap;
}

:root {
  --header-height: 100px;
  --home-header-height: 230px;
  --home-header-scroll: 120px;
  --granted-secure-banner-height: 0px;
  @include media-breakpoint-down(lg) {
    --header-height: 64px;
    --home-header-height: var(--header-height);
  }
}

body {
  font-family: $g-font-family;
  background-color: color(black);
}

// styling for the ~shared/Input/InternationalPhoneNumber

.international-phone-number-input {
  .selected-flag {
    background-color: transparent !important;
  }

  .selected-flag.open {
    background-color: transparent !important;
  }

  .country.highlight {
    background-color: transparent !important;
  }
}

@include media-breakpoint-down(md) {
  button {
    outline: none;
  }
}

// h tags setup
@each $key, $value in $heading-sizes {
  #{'' + $key} {
    font-size: $value;
    line-height: 1.23;
  }
}

.neon {
  box-shadow: 0 0 50px 1px $g-primary, inset 0 0 50px 1px $g-primary;
  border: 7px solid $g-primary;
}

@keyframes blink {
  40% {
    opacity: 1;
  }
  42% {
    opacity: 0.8;
  }
  43% {
    opacity: 1;
  }
  45% {
    opacity: 0.2;
  }
  46% {
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.list {
  list-style: none;
  position: sticky;
  top: 20px;
  letter-spacing: 0.1em;

  a {
    color: #8b8b8b;
  }
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: map-get($spaces, sm);
  }

  &::-webkit-scrollbar-track {
    background-color: map-get($colors, gray);
    border-radius: 25px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $g-primary;
    border-radius: 25px;
  }
}

// tmp styles

.form {
  display: flex;
  flex-direction: column;
}

.formItem {
  .alert {
    color: color(red);
    font-size: 14px;
    margin-top: 5px;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: #ffffff;
  background: #ffffff60;
}

::selection {
  color: #ffffff;
  background: #ffffff60;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

.red {
  background-color: red;
}

.rc-dropdown {
  font-family: inherit;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
}
