@use '/styles/variables' as *;
@use '/styles/media' as *;

@keyframes fade-in-mask {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out-mask {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-in-modal {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fade-out-modal {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(100px);
  }
}

.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: rgba($color: #000, $alpha: 0.3);
  backdrop-filter: blur(20px);
  z-index: 1000;

  animation-duration: 0.2s;
  animation-fill-mode: both;

  &-fade-in {
    animation-name: fade-in-mask;
  }

  &-fade-out {
    animation-name: fade-out-mask;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;

  @include media-breakpoint-down(md) {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button {
  position: absolute;
  top: map-get($spaces, lg) / 2;
  right: map-get($spaces, lg) / 2;

  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0.6em;
  display: flex;
  border-radius: 50%;
  transition: 0.3s background-color;
  z-index: 1;

  & > svg {
    width: 1em;
    height: 1em;
    fill: rgba(255, 255, 255, 0.4);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.26);
  }
}

.modal {
  // default modal styles
  width: 100%;
  max-width: 440px;
  background-color: color(elevated);
  margin: 0 auto;
  top: 100px;
  position: relative;
  padding: map-get($spaces, lg);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  max-height: 100%;

  border: 1px solid color(gray);
  background-color: map-get($colors, dark-gray);
  border-radius: 12px;

  animation-duration: 0.2s;
  animation-fill-mode: both;

  &-fade-in {
    animation-name: fade-in-modal;
  }

  &-fade-out {
    animation-name: fade-out-modal;
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
    top: 0;
  }
}
