@use '/styles/variables' as *;

.wrapper {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.input {
  position: absolute;
  outline: none;
  opacity: 0;

  &:disabled {
    + .checkbox {
      background-color: map-get($colors, g-gray-5);
    }

    ~ .label {
      color: map-get($colors, g-gray-2);
    }
  }

  &:disabled:checked {
    + .checkbox {
      background-color: map-get($colors, g-gray-2);
    }

    ~ .label {
      color: map-get($colors, g-gray-2);
    }
  }

  &:not([disabled]):not(:checked):hover,
  &:not([disabled]):not(:checked):focus {
    + .checkbox {
      border: 1px solid color(white);
      box-shadow: 0px 0px 25px transparentize($g-primary, 0.5),
        0px 0px 50px transparentize($g-primary, 0.8),
        inset 0px 0px 25px transparentize($g-primary, 0.8);
    }
  }

  &:checked + .checkbox {
    border: 1px solid color(white);

    box-shadow: 0px 0px 25px transparentize($g-primary, 0.5),
      0px 0px 50px transparentize($g-primary, 0.8),
      inset 0px 0px 25px transparentize($g-primary, 0.8);
  }

  &:not([disabled]):checked:hover,
  &:not([disabled]):checked:focus {
    + .checkbox {
      box-shadow: 0px 0px 25px transparentize($g-primary, 0.4),
        0px 0px 50px transparentize($g-primary, 0.7),
        inset 0px 0px 25px transparentize($g-primary, 0.7);
    }
  }

  &:checked + .checkbox::after {
    opacity: 1;
    transform: scale(1);
  }
}

.label {
  padding-left: 20px;
  color: map-get($colors, g-white);
  font-size: map-get($font-sizes, body);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.uppercase {
    text-transform: uppercase;
    letter-spacing: $g-letter-spacing-uppercase;
  }
}

.checkbox {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  background-color: color(black);
  border: 1px solid color(gray);
  position: relative;
  transition: 0.3s;

  &::after {
    width: 12px;
    height: 12px;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;

    opacity: 0;
    transform: scale(0);
    transform-origin: center;

    transition: 0.3s;

    background-image: url("data:image/svg+xml,%3Csvg height='512' viewBox='0 0 515.556 515.556' width='512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M0 274.226l176.549 176.886L515.556 112.44l-48.67-47.997-290.337 290L47.996 225.891z'/%3E%3C/svg%3E%0A");
    background-size: cover;
  }

  &-indeterminate::after {
    background-image: url("data:image/svg+xml,%3Csvg height='512' viewBox='0 0 515.556 515.556' width='512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='black' d='M0 274.226l176.549 176.886L515.556 112.44l-48.67-47.997-290.337 290L47.996 225.891z'/%3E%3C/svg%3E%0A");
  }
}
