@use '/styles/variables' as *;

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -33% 50%;
  }
}

.button {
  letter-spacing: $g-letter-spacing;
  font-size: map-get($font-sizes, body);
  line-height: map-get($line-heights, body);
  border-radius: map-get($border-radiuses, md);
  border: none;
  padding: 0.5em 1.5em;
  border: 1px solid transparent;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  color: color(white);

  will-change: box-shadow;
  transition: color 0.05s ease-in-out, box-shadow 0.05s ease-in-out, border-color 0.05s ease-in-out,
    text-shadow 0.05s ease-in-out;

  &:disabled {
    cursor: unset;
    background-color: transparent;
    pointer-events: none;
    border-color: color(gray);
    color: color(gray);
  }

  svg {
    will-change: filter;
    transition: filter 0.05s ease-in-out;
  }

  &-icon {
    padding: 0.5em 0.5em;
  }

  &-text-and-icon {
    & svg {
      margin-left: 0.4em;
    }

    &.icon-left {
      flex-direction: row-reverse;
      svg {
        margin-left: 0;
        margin-right: 0.4em;
      }
    }
  }

  &-fluid {
    width: 100%;
  }

  &.loading {
    background: repeating-linear-gradient(
      90deg,
      map-get($colors, g-gray-3),
      map-get($colors, g-gray-2),
      map-get($colors, g-gray-3)
    );
    animation: loading 1s linear infinite;
    background-repeat: repeat-x;
    background-size: 400% 110%;
    color: map-get($colors, g-gray-1);
    border-color: transparent;
    pointer-events: none;

    &:disabled {
      color: map-get($colors, g-gray-1);
    }
  }
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: $g-letter-spacing-uppercase;
}

.primary {
  border: 1px solid color(gray);
  background-color: transparent;

  &:hover {
    border-color: color(white);
  }
}

.secondary {
  border-color: transparent;
  background-color: transparent;

  &:disabled {
    border-color: transparent;
  }
}

.big {
  font-size: map-get($font-sizes, lg);
  padding: 0.8em 2em;
}

@each $key, $value in $colors {
  .#{'body-' + $key} {
    background-color: $value;
  }

  .#{'font-' + $key} {
    color: $value;
  }

  .#{'primary-default-' + $key} {
    box-shadow: 0px 0px 35px transparentize($value, 0.9), 0px 0px 25px transparentize($value, 0.75),
      0px 0px 50px transparentize($value, 0.85), inset 0px 0px 25px transparentize($value, 0.75),
      inset 0px 0px 50px transparentize($value, 0.85);
    border-color: color(light-gray);
    text-shadow: 0 0 5px $value, 0 0 10px $value, 0 0 25px $value;
  }

  .#{'secondary-default-' + $key} {
    @extend %text-shadow-#{'' + $key};
  }

  .#{'' + $key} {
    &.primary:hover {
      box-shadow: 0px 0px 35px transparentize($value, 0.9),
        0px 0px 25px transparentize($value, 0.75), 0px 0px 50px transparentize($value, 0.85),
        inset 0px 0px 25px transparentize($value, 0.75),
        inset 0px 0px 50px transparentize($value, 0.85);

      text-shadow: 0 0 5px $value, 0 0 10px $value, 0 0 25px $value;
    }

    &.secondary:hover {
      @extend %text-shadow-#{'' + $key};
    }

    &:hover {
      svg {
        @extend %filter-shadow-#{'' + $key};
      }
    }
  }
}
