@import '/styles/variables';
@import '/styles/media';

.heading {
  letter-spacing: $g-letter-spacing;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@each $key, $value in $colors {
  .#{'' + $key} {
    color: $value;
  }
  .#{'glow-' + $key} {
    @extend %text-shadow-light-#{'' + $key};
  }
}

@each $key, $value in $heading-sizes {
  .#{'' + $key} {
    font-size: $value;
  }
}

@each $key, $value in $font-weights {
  .#{'w' + $key} {
    font-weight: $value;
  }
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: $g-letter-spacing-uppercase;
}

.center {
  text-align: center;
}
