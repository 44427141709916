@import '/styles/media';
@import '/styles/variables';

.Notification {
  --progress-duration: 4s;
  --progress-color: black;

  --toastify-toast-width: 100%;

  max-width: $max-page-width-sm;
  @include media-breakpoint-down(md) {
    max-width: 600px;
    padding: 20px;
    div {
      border-radius: 8px;
    }
  }

  .toast-message {
    font-family: 'Poppins', sans-serif;
    display: grid;
    gap: 12px;
    grid-auto-columns: 50px 1fr 50px;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;

    .icon {
      color: var(--progress-color);
      fill: currentColor;
    }

    span {
      justify-self: flex-start;
      @include media-breakpoint-down(md) {
        grid-row: 2/2;
        grid-column: 1/3;
      }
    }

    button {
      &:hover {
        cursor: pointer;
      }
      justify-self: flex-end;
    }

    @include media-breakpoint-down(md) {
      grid-auto-columns: 1fr 1fr;
      grid-auto-rows: auto 1fr;
    }
  }

  @each $key, $value in $colors {
    .#{'' + $key} {
      color: $value;
    }
    .#{'' + $key}-background {
      background-color: $value;
    }
  }

  .circle-progress-container {
    border: 0;
    background-color: transparent;
    @include media-breakpoint-down(md) {
      font-size: 0.8rem;
    }
    color: var(--progress-color);
    position: relative;
    height: 2.5em;
    width: 2.5em;
    padding: 0;

    .circle-progress {
      height: 2.5em;
      width: 2.5em;

      circle {
        stroke-width: 1px;
        stroke-dasharray: 130;
        stroke: var(--progress-color);
        animation: spin calc(var(--progress-duration) + 0.5s);
      }
      :first-child {
        animation: none;
      }
      :last-child {
        opacity: 0.7;
      }
    }

    .circle-child-overlapped {
      height: 0.7em;
      width: 0.7em;
      position: absolute;
      color: var(--progress-color);
      left: calc(50% - 0.3em);
      top: calc(50% - 0.3em);
    }
  }

  @keyframes spin {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 130;
    }
  }
}
