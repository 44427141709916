@use 'sass:map';
@import '/styles/variables';

.link {
  transition: all 0.3s ease-in-out;
  letter-spacing: $g-letter-spacing;
  text-decoration: none;
  color: color(white);

  svg {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    @extend %text-shadow-light-primary;

    transition: all 0.05s ease-in-out;

    svg {
      @extend %filter-shadow-primary;

      transition: all 0.05s ease-in-out;
    }
  }

  &:active {
    transform: scale(0.97);
    transition: all 0.05s ease-in-out;
  }

  .icon {
    width: 0.7em;
    height: 0.7em;
    color: currentColor;
  }

  &.uppercase {
    text-transform: uppercase;
    letter-spacing: $g-letter-spacing-uppercase;
  }

  &.uppercase {
    text-transform: uppercase;
    letter-spacing: $g-letter-spacing-uppercase;
  }
}

.external {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  column-gap: 2px;
}

.bold {
  font-weight: 600;
}

@each $key, $value in map.remove($colors, white, g-white) {
  //remove white to not interfere with primary hover style
  .#{'' + $key} {
    color: $value;
    &:hover {
      @extend %text-shadow-light-#{'' + $key};

      transition: all 0.05s ease-in-out;

      svg {
        @extend %filter-shadow-#{'' + $key};

        transition: all 0.05s ease-in-out;
      }
    }
  }
}

@each $key, $value in $font-sizes {
  .#{'' + $key} {
    font-size: $value;
  }
}
