@use '/styles/variables' as *;
@use '/styles/utils' as u;

.button_circle {
  background-color: transparent;
  border: none;
  padding: 10px;
  border-radius: 50%;
  line-height: 1;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s linear background-color;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: map-get($colors, g-gray-5);
  }
  &:disabled {
    background-color: color(light-gray);
  }
}

.indicator {
  width: 20px;
  min-width: 20px;
  height: 20px;
  padding: 2px;
  line-height: 17px;
  font-size: 14px;
  width: fit-content;
  border-radius: 1em;
  color: $g-primary-outline;
  background-color: u.g-dimm($g-primary);
  position: absolute;
  top: -5px;
  right: -5px;
  white-space: nowrap;
  overflow: hidden;
}
