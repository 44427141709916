@use '/styles/variables' as *;

.text {
  margin-bottom: 24px;
  display: block;
}

.modal {
  h4 {
    margin-bottom: 0.5em;
    font-family: $g-font-family;
    font-weight: 400 !important;

    @extend %text-shadow-light-green;
  }
}

.form {
  display: grid;
}
