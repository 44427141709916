@import '/styles/variables';

.typography {
  font-weight: normal;
  letter-spacing: 0.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p {
  margin-bottom: 1em;
}

.center {
  text-align: center;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: $g-letter-spacing-uppercase;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.line-clamp {
  --line-clamp: 1;
  -webkit-line-clamp: var(--line-clamp);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
}

@each $key, $value in $colors {
  .#{'' + $key} {
    color: $value;
  }
}

@each $key, $value in $line-heights {
  .lh-#{'' + $key} {
    line-height: $value;
  }
}

@each $key, $value in $font-sizes {
  .#{'' + $key} {
    font-size: $value;
  }
}
