@use '/styles/variables' as *;
@use '/styles/media' as *;
@use '/styles/utils' as u;

.wrapper {
  letter-spacing: $g-letter-spacing;
  font-family: $g-font-family;
  display: flex;
  flex-flow: column;
  position: relative;

  .error-message {
    min-height: map-get($spaces, xxl);
    width: 100%;
    text-align: start;
    color: transparent;
    font-size: map-get($font-sizes, sm);
    margin-top: 8px;
    &-active {
      color: color(red);
    }
  }

  .input-wrapper {
    width: 100%;
    border-radius: map-get($border-radiuses, md);
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    color: map-get($colors, g-gray-1);
    transition: 0.15s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    & > svg {
      position: absolute;
      pointer-events: none;
      left: 1rem;
      transition: inherit;
    }

    .icon-buttons {
      position: absolute;
      right: 0.5rem;
      width: fit-content;
      transition: inherit;
    }

    & input {
      background-color: transparent;
      border: none;
      width: 100%;
      padding: 10px 16px;
      outline: none;
      font-weight: normal;
      border-radius: map-get($border-radiuses, md);
      border: 1px solid color(gray);
      color: color(white);
      caret-color: $g-primary-outline;
      transition: inherit;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: color(white) !important;
        background-color: color(black) !important;
        background-clip: content-box !important;
        -webkit-box-shadow: 0 0 0 1000px black inset !important;
      }
    }

    &.large {
      input {
        &:focus,
        &:active,
        &:valid,
        &:not(&:placeholder-shown) {
          padding-top: 24px;
          padding-bottom: 12px;

          &:not(:disabled) {
            & + label {
              font-size: 13px;
              top: 0;
            }
          }
        }
      }

      .label {
        position: absolute;
        top: 12px;
        display: flex;
        align-items: center;
        pointer-events: none;
        padding-left: 1em;
        transition: top 0.2s, font-size 0.2s;
        &.label-error {
          color: color(red);
        }
      }
    }

    &:not(.error) {
      input:not(:disabled) {
        &:hover,
        &:focus,
        &:focus-within {
          border-color: $g-primary-outline;
          background-color: u.g-dimmest($g-primary);

          @extend %basic-shadow-primary;
        }

        &:hover + svg,
        &:focus + svg,
        &:active + svg {
          @extend %filter-shadow-primary;
        }
      }
    }

    &.error {
      input {
        border-color: $g-primary-outline;
        @extend %basic-shadow-red;
      }

      svg {
        @extend %filter-shadow-red;
      }
    }

    & input::placeholder {
      color: map-get($colors, g-gray-1);
    }

    & input:disabled {
      &::placeholder {
        color: map-get($colors, g-gray-3);
      }

      color: color(gray);

      border-color: color(gray);
      box-shadow: none;

      & ~ svg,
      & ~ button > svg,
      & ~ label > svg {
        color: color(gray) !important;
        filter: none;
      }

      &:not(&:placeholder-shown) + label {
        font-size: 13px;
        color: color(gray);
        top: 0;
      }

      & ~ .label-error {
        color: color(gray) !important;
      }
    }

    &.disabled {
      color: map-get($colors, g-gray-3);
    }

    &:not(.large) {
      &.withIcon input {
        padding-left: 2.5em;
      }
    }
  }
}
