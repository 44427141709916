@use '/styles/utils' as u;
@use '/styles/variables' as *;
@use '/styles/media' as *;

.logo {
  $logo-light: u.g-dimmer(color(g-green));
  margin: auto;

  cursor: pointer;
  white-space: nowrap;

  transition: transform 0.3s ease-in-out;

  &:hover {
    filter: shadows(svg, ($logo-light), (4px));
    transition: all 0.05s ease-in-out;
  }

  &:active {
    transform: scale(0.98);
    transition: all 0.05s ease-in-out;
  }

  &.big {
    transform: scale(1.7);
  }
}
